<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.SUBADMINS')">
          <template v-slot:toolbar>
            <b-button variant="primary" class="mr-4" @click="openCreateModal()">
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              :disabled="selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
            <!-- Add modal -->
            <b-modal
              id="addSubAdmin"
              ref="addSubAdmin"
              :title="modalTitle"
              class="chPass-modal"
            >
              <b-container fluid>
                <div role="alert" class="alert alert-info">
                  <div class="alert-text">
                    {{ $t("SUB_ADMINS.DESC") }}
                  </div>
                </div>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="username">{{ $t("COMMON.USERNAME") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="username"
                      v-model="$v.addForm.username.$model"
                      :state="validateState('username')"
                      :placeholder="$t('COMMON.USERNAME')"
                      aria-describedby="input-username-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-username-feedback">{{
                      $t("VALIDATION.REQUIRED_FIELD")
                    }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="password">{{ $t("COMMON.PASSWORD") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="password"
                      v-model="$v.addForm.password.$model"
                      :state="validateState('password')"
                      :placeholder="$t('COMMON.PASSWORD')"
                      aria-describedby="input-password-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-password-feedback">{{
                      $t("VALIDATION.REQUIRED_FIELD")
                    }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="email">{{ $t("LISTS.EMAIL_ADDRESS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="email"
                      v-model="$v.addForm.email.$model"
                      :state="validateState('email')"
                      :placeholder="$t('LISTS.EMAIL_ADDRESS')"
                      aria-describedby="input-email-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-email-feedback">{{
                      $t("VALIDATION.REQUIRED_FIELD")
                    }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="fullname">{{ $t("COMMON.FULL_NAME") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="fullname"
                      v-model="$v.addForm.fullname.$model"
                      :state="validateState('fullname')"
                      :placeholder="$t('COMMON.FULL_NAME')"
                      aria-describedby="input-fullname-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-fullname-feedback">{{
                      $t("VALIDATION.REQUIRED_FIELD")
                    }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="accountHistory">{{
                      $t("SUB_ADMINS.ACCOUNT_HISTORY")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.accountHistory.selected"
                      value-field="value"
                      text-field="name"
                      :options="accountHistoryData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="users">{{ $t("MENU.USERS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.users.selected"
                      value-field="value"
                      text-field="name"
                      :options="usersData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="domains">{{ $t("MENU.DOMAINS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.domains.selected"
                      value-field="value"
                      text-field="name"
                      :options="domainsData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="aliases">{{ $t("MENU.ALIASES") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.aliases.selected"
                      value-field="value"
                      text-field="name"
                      :options="aliasesData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="lists">{{ $t("MENU.LISTS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.lists.selected"
                      value-field="value"
                      text-field="name"
                      :options="listsData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="dglists">{{ $t("SUB_ADMINS.DGLISTS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.dglists.selected"
                      value-field="value"
                      text-field="name"
                      :options="dglistsData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="branding">{{ $t("MENU.BRANDING") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.branding.selected"
                      value-field="value"
                      text-field="name"
                      :options="brandingData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="filtering">{{ $t("MENU.FILTERING") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.filtering.selected"
                      value-field="value"
                      text-field="name"
                      :options="filteringData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label for="logs">{{ $t("MENU.LOGS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.logs.selected"
                      value-field="value"
                      text-field="name"
                      :options="logsData"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateItemInfor()"
                  v-if="isEdit"
                >
                  {{ $t("COMMON.UPDATE") }}
                </b-button>
                <b-button variant="primary" v-on:click="createItem()" v-else>
                  {{ $t("SUB_ADMINS.ADD_SUBADMIN") }}
                </b-button>
              </template>
            </b-modal>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="subAdmins"
              :single-select="singleSelect"
              :search="search"
              show-select
              item-key="id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <template v-if="!isMobile">
                    <tr v-for="item in items" :key="item.id">
                      <td>
                        <v-checkbox
                          v-model="selected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          class="cusTable-checkbox"
                          color="rgba(0,0,0,.54)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-start">
                        {{ item.username }}
                      </td>
                      <td class="text-start">
                        <v-icon
                          medium
                          color="dark"
                          class="mr-4"
                          @click="showItemInfor(item)"
                          v-b-tooltip.hover
                          :title="$t('COMMON.EDIT')"
                        >
                          edit
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          @click="saveDeltem(item)"
                          v-b-modal.confirm-2
                          v-b-tooltip.hover
                          :title="$t('COMMON.DELETE')"
                        >
                          delete
                        </v-icon>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :class="{
                        unactiveRow: item.active == 0,
                      }"
                    >
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header"></div>
                        <v-checkbox
                          v-model="selected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          class="cusTable-checkbox"
                          color="rgba(0,0,0,.54)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.USERNAME") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          {{ item.username }}
                        </div>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.ACTION") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-icon
                            medium
                            color="dark"
                            class="mr-4"
                            @click="showItemInfor(item)"
                            v-b-tooltip.hover
                            :title="$t('COMMON.EDIT')"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            class="mr-4"
                            @click="showItemFooter(item)"
                            v-b-modal.item-footer
                            v-b-tooltip.hover
                            :title="$t('COMMON.FOOTER')"
                          >
                            computer
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            @click="saveDeltem(item)"
                            v-b-modal.confirm-2
                            v-b-tooltip.hover
                            :title="$t('COMMON.DELETE')"
                          >
                            delete
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-data-table>

            <!-- Delete confirm modals -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem.username }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required } from "vuelidate/lib/validators";
// const mustBeDot = value =>
//   value.lastIndexOf(".") > 0 && value.length - value.lastIndexOf(".") != 1;
/**
 * Sample widgets data source
 */

export default {
  mixins: [validationMixin],
  name: "subadmins",
  components: {
    KTPortlet,
  },
  created() {
    this.getSubAdmins();
    if (window.innerWidth < 600) this.isMobile = true;
    window.addEventListener("resize", this.resizeWin);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWin);
  },

  data: () => ({
    search: "",
    singleSelect: false,
    selected: [],
    isEdit: false,
    modalTitle: "",
    updateId: "",
    addForm: {
      username: "",
      password: "",
      email: "",
      fullname: "",
      accountHistory: {
        selected: 0,
      },
      users: {
        selected: 0,
      },
      domains: {
        selected: 0,
      },
      aliases: {
        selected: 0,
      },
      lists: {
        selected: 0,
      },
      dglists: {
        selected: 0,
      },
      branding: {
        selected: 0,
      },
      filtering: {
        selected: 0,
      },
      logs: {
        selected: 0,
      },
    },
    subAdmins: [],
    delItem: "",
    updateItem: "",
    isMobile: false,
  }),
  validations: {
    addForm: {
      username: {
        required,
      },
      password: {
        required,
      },
      email: {
        required,
      },
      fullname: {
        required,
      },
    },
  },
  computed: {
    headers: function () {
      return [
        { text: this.$t("MENU.SUBADMINS"), value: "username" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    accountHistoryData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
      ];
    },
    logsData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
      ];
    },
    usersData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
        { value: 5, name: "2 - " + this.$t("SUB_ADMINS.ADD_UPDATE") },
        { value: 6, name: "3 - " + this.$t("SUB_ADMINS.ENABLE_DISABLE") },
        { value: 7, name: "4 - " + this.$t("SUB_ADMINS.REMOVE") },
        { value: 8, name: "5 - " + this.$t("SUB_ADMINS.IMPERSONATE") },
      ];
    },
    domainsData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
        { value: 5, name: "2 - " + this.$t("SUB_ADMINS.ADD_UPDATE") },
        { value: 7, name: "3 - " + this.$t("SUB_ADMINS.REMOVE") },
      ];
    },
    aliasesData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
        { value: 5, name: "2 - " + this.$t("SUB_ADMINS.ADD_UPDATE") },
        { value: 7, name: "3 - " + this.$t("SUB_ADMINS.REMOVE") },
      ];
    },
    listsData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
        { value: 5, name: "2 - " + this.$t("SUB_ADMINS.ADD_UPDATE") },
        { value: 7, name: "3 - " + this.$t("SUB_ADMINS.REMOVE") },
      ];
    },
    dglistsData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
        { value: 5, name: "2 - " + this.$t("SUB_ADMINS.ADD_UPDATE") },
        { value: 7, name: "3 - " + this.$t("SUB_ADMINS.REMOVE") },
      ];
    },
    brandingData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
        { value: 5, name: "2 - " + this.$t("SUB_ADMINS.MODIFY") },
      ];
    },
    filteringData: function () {
      return [
        { value: 0, name: "0 - " + this.$t("SUB_ADMINS.NO_ACCESS") },
        { value: 1, name: "1 - " + this.$t("SUB_ADMINS.VIEW") },
        { value: 5, name: "2 - " + this.$t("SUB_ADMINS.MODIFY") },
        { value: 7, name: "3 - " + this.$t("SUB_ADMINS.REMOVE") },
      ];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.SUBADMINS") },
    ]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },

    /*  -----modal close------- */
    modalClose() {
      this.resetAddForm();
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs["addSubAdmin"].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },
    //  resize windows for mobile responsive
    resizeWin() {
      if (window.innerWidth < 600) this.isMobile = true;
      else this.isMobile = false;
    },
    /* ----------reset addform----- */
    resetAddForm() {
      this.addForm.username = "";
      this.addForm.password = "";
      this.addForm.email = "";
      this.addForm.fullname = "";
      this.addForm.accountHistory.selected = 0;
      this.addForm.users.selected = 0;
      this.addForm.domains.selected = 0;
      this.addForm.aliases.selected = 0;
      this.addForm.lists.selected = 0;
      this.addForm.dglists.selected = 0;
      this.addForm.branding.selected = 0;
      this.addForm.filtering.selected = 0;
      this.addForm.logs.selected = 0;
    },
    /* ----------get all domain----- */
    getSubAdmins() {
      this.subAdmins = [];
      this.selected = [];

      postRequest({
        action: "getSubAdmins",
        token: localStorage.id_token,
      }).then((res) => {
        if (res.returncode) {
          this.subAdmins = res.returndata;
        }
      });
    },
    /* ------create item---- */
    openCreateModal() {
      this.$refs["addSubAdmin"].show();
      this.modalTitle = this.$t("SUB_ADMINS.ADD_SUBADMIN");
      this.isEdit = false;
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.$anyError) {
        return;
      }
      postRequest({
        action: "addSubAdmin",
        token: localStorage.id_token,
        adminname: this.addForm.username,
        password: this.addForm.password,
        uname: this.addForm.fullname,
        emailadmin: this.addForm.email,
        accounthistory: this.addForm.accountHistory.selected,
        logs: this.addForm.logs.selected,
        branding: this.addForm.branding.selected,
        filtering: this.addForm.filtering.selected,
        domains: this.addForm.domains.selected,
        aliases: this.addForm.aliases.selected,
        lists: this.addForm.lists.selected,
        dglists: this.addForm.dglists.selected,
        users: this.addForm.users.selected,
      }).then((res) => {
        this.modalClose();
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getSubAdmins();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },
    deleteItem(id) {
      postRequest({
        action: "removeSubAdmin",
        token: localStorage.id_token,
        id: id,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        this.makeToastVariant("success", res.returndata);
        for (let i = 0; i < this.subAdmins.length; i++) {
          if (this.subAdmins[i].id == id) {
            this.subAdmins.splice(i, 1);
            break;
          }
        }
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteItem(item.id);
    },
    deleteItems() {
      if (this.selected.length > 0) {
        this.selected.forEach((el) => {
          this.deleteItem(el.id);
        });
      }
    },

    /* ------edit item----- */
    showItemInfor(item) {
      postRequest({
        action: "getSubAdminInfo",
        token: localStorage.id_token,
        id: item.id,
      }).then((res) => {
        if (res.returncode) {
          this.$refs["addSubAdmin"].show();
          this.modalTitle = this.$t("COMMON.UPDATE") + item.username;
          this.isEdit = true;
          this.updateId = item.id;

          this.addForm.username = res.returndata[0].username;
          this.addForm.password = "";
          this.addForm.email = res.returndata[0].emailadmin;
          this.addForm.fullname = res.returndata[0].uname;
          this.addForm.accountHistory.selected =
            res.returndata[0].accounthistory;
          this.addForm.users.selected = res.returndata[0].users;
          this.addForm.domains.selected = res.returndata[0].domains;
          this.addForm.aliases.selected = res.returndata[0].aliases;
          this.addForm.lists.selected = res.returndata[0].lists;
          this.addForm.dglists.selected = res.returndata[0].dglists;
          this.addForm.branding.selected = res.returndata[0].branding;
          this.addForm.filtering.selected = res.returndata[0].filtering;
          this.addForm.logs.selected = res.returndata[0].logs;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    updateItemInfor() {
      postRequest({
        action: "updateSubAdmin",
        token: localStorage.id_token,
        adminid: this.updateId,
        adminname: this.addForm.username,
        password: this.addForm.password,
        uname: this.addForm.fullname,
        emailadmin: this.addForm.email,
        accounthistory: this.addForm.accountHistory.selected,
        logs: this.addForm.logs.selected,
        branding: this.addForm.branding.selected,
        filtering: this.addForm.filtering.selected,
        domains: this.addForm.domains.selected,
        aliases: this.addForm.aliases.selected,
        lists: this.addForm.lists.selected,
        dglists: this.addForm.dglists.selected,
        users: this.addForm.users.selected,
      }).then((res) => {
        if (res.returncode) {
          this.getSubAdmins();
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose();
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
